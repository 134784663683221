import Rellax from 'rellax'

/**
 * main visual scroll Parallax animation
 */

const parallaxSet = () => {
  var rellax = new Rellax('.rellax', {
    speed: -2,
    center: false,
    wrapper: null,
    round: true,
    vertical: true,
    horizontal: false,
  })
}

export { parallaxSet }
