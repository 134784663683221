import inView from 'in-view'

/**
 * scroll inView animation
 */
const inViewSet = () => {
  inView('.inview').on('enter', (el) => {
    el.classList.add('is-view')
  })

  inView('.js-fadeUp').on('enter', (el) => {
    el.classList.add('is-view')
  })

  inView('.js-fadeLeft').on('enter', (el) => {
    el.classList.add('is-view')
  })

  inView('.js-fadeIn').on('enter', (el) => {
    el.classList.add('is-view')
  })
}

export { inViewSet }
