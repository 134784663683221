import { ua } from './modules/ua'
import { inViewSet } from './modules/inView'
import { parallaxSet } from './modules/parallax'

class App {
  constructor() {
    this.body = document.querySelector('body')
    this.init()
  }

  init() {
    // 全ページ共通適用
    this.body.classList.add(`is-${ua.browser()}`)
    this.body.classList.add(`is-${ua.os()}`)
    this.pageCommonSetting()

    window.addEventListener('resize', () => {
      //
    })
    window.addEventListener('scroll', () => {
      //
    })
  }

  pageCommonSetting() {
    // ナビゲーション開閉

    const navigationTrigger = document.querySelectorAll('.navigation-trigger')
    navigationTrigger.forEach((btn) => {
      btn.addEventListener('click', () => {
        document.body.classList.toggle('view-nav')
      })
    })

    const navigationMenu = document.querySelectorAll('.drawer-menu__nav-list-item a')
    navigationMenu.forEach((menuBtn) => {
      menuBtn.addEventListener('click', () => {
        document.body.classList.remove('view-nav')
      })
    })

    // スムーススクロール
    const smoothScrollTarget = Array.from(document.querySelectorAll('.js-smooth-scroll'))
    if (smoothScrollTarget.length > 0) {
      smoothScrollTarget.forEach((link) => {
        link.addEventListener('click', (e) => {
          e.preventDefault()
          const targetId = e.target.hash
          document.querySelector('body').classList.remove('view-nav')

          if (!document.querySelector(targetId)) {
            return false
          }
          scrollSmooth(targetId)
        })
      })
    }

    // page top
    const pageTopButton = document.querySelector('.footer__page-top')
    pageTopButton.addEventListener('click', () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    })
    //
    const buttons = document.querySelectorAll('.btn-posnawr')

    $(function () {
      $('.button')
        .on('mouseenter', function (e) {
          var parentOffset = $(this).offset(),
            relX = e.pageX - parentOffset.left,
            relY = e.pageY - parentOffset.top
          $(this).find('.button__item').css({ top: relY, left: relX })
        })
        .on('mouseout', function (e) {
          var parentOffset = $(this).offset(),
            relX = e.pageX - parentOffset.left,
            relY = e.pageY - parentOffset.top
          $(this).find('.button__item').css({ top: relY, left: relX })
        })
      $('a[href^="#"]').click(function () {
        return false
      })
    })
    //
    inViewSet()
    parallaxSet()
  }
}

document.addEventListener('DOMContentLoaded', () => {
  window.vMode = new App()
})

window.addEventListener('load', () => {})
